import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useEffect, useRef, useState } from "react";
import { BooleanInput, Labeled, SelectInput, TextInput } from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { FieldArray } from "redux-form";
import AceEditorInput from "../../../components/AceEditorInputSimple";
import CommonDynamicFieldsComponents from "../../../components/CommonDynamicFieldsComponents";
import FieldArrayCardFormComponent from "../../../components/FieldArrayCardFormComponent";
import Grid from "../../../components/Grid";
import { useOptionsContext } from "../../../hooks/useOptionsFiltered";
import { getSource } from "../../../tools";

const colorPickerInPutField = (index, sourcePrefix, labelPrefix) => {
  return (
    <ColorInput
      key={index}
      source={sourcePrefix + "_" + (index + 1)}
      picker="Sketch"
      label={labelPrefix + "-" + (index + 1)}
      style={{ display: "inline-flex" }}
    />
  );
};

const CardAppleNews = ({
  resource,
  source = "",
  record,
  formData,
  appleNewsExpanded = false,
  isDefault = false,
}) => {
  const sourceForm = source && source.replace(/\.$/, "");
  const recordSource = (source && getSource(formData, sourceForm)) || record;

  const [isDefaultState] = useState(isDefault);
  const { options, updateOptions } = useOptionsContext();
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      // Cleanup function to unsubscribe from updates when unmounting
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;
    if(recordSource.region){
      updateOptions(recordSource.region, "mount", options);
    }
    //onunmout CardAppleNews, make option available again
    return () => {
      if(recordSource.region){
        //TODO: fix an issue on the behavior of the options list when removing an CardAppleNews with a specific region, it needs to be available back after delete but it's not currently
        updateOptions(recordSource.region, "unmount", options);
      }
    };
  }, [recordSource.region]);

  const handleCallBack = () => {
    fetch("/api/" + resource + "_anf/" + record.guid, { method: "GET" });
  };

  return (
    <Grid columns={1}>
      {!isDefaultState && (
        <SelectInput
          label={"Region"}
          source={`${source}region`}
          optionValue={`id`}
          choices={options}
        />
      )}
      <Collapse in={appleNewsExpanded} timeout="auto">
        <CardContent>
          <BooleanInput
            source={`${source}apple_news_preprod`}
            format={(value) => {
              if (undefined === value) {
                return true;
              }
              return value;
            }}
            defaultValue={true}
            label={"Apple news sandbox"}
          />
          <BooleanInput
              source={`${source}apple_news_premium`}
              defaultValue={false}
              label={"Apple news Premium"}
              helperText="Once activated, you need to add the following control tags to place your articles in Apple News Premium: 'van-paywall', 'van-paywall-premium'"
          />
          <TextInput
            source={`${source}apple_news_chanel_key`}
            helperText="Used for connecting the Apple News Api"
            label={"Apple News channel key"}
            fullWidth
            style={{ display: "block" }}
          />
          <TextInput
            source={`${source}apple_news_key`}
            helperText="Used for connecting the Apple News Api"
            label={"Apple News Key"}
            fullWidth
            style={{ display: "block" }}
          />
          <TextInput
            source={`${source}apple_news_secret`}
            helperText="Used for connecting the Apple News Api"
            label={"Apple News Secret"}
            fullWidth
            style={{ display: "block" }}
          />
          {isDefaultState && (
            <>
              <Grid columns={5}>
                <Grid>
                  <Labeled
                    style={{ marginTop: 10 }}
                    guid="brand_colors"
                    label="Brand Colors (link and button)"
                  />
                  {window.brandLengthColors > 0 &&
                    Array.from(Array(window.brandLengthColors)).map(
                      (field, index) =>
                        colorPickerInPutField(
                          index,
                          `${source}brand_color`,
                          "brand-color"
                        )
                    )}
                </Grid>
                <Grid>
                  <Labeled
                    style={{ marginTop: 10 }}
                    guid="secondary_colors"
                    label="Secondary colors (text)"
                  />
                  {window.secondaryLengthColors > 0 &&
                    Array.from(Array(window.secondaryLengthColors)).map(
                      (field, index) =>
                        colorPickerInPutField(
                          index,
                          `${source}secondary_color`,
                          "secondary-color"
                        )
                    )}
                </Grid>
                <Grid>
                  <Labeled
                    style={{ marginTop: 10 }}
                    guid="header_fonts"
                    label="Header Font"
                  />
                  <SelectInput
                    style={{ marginTop: 10 }}
                    guid="header_fonts"
                    label="Header Font"
                    source={`${source}header_fonts`}
                    choices={window.headerAllFont.map((field) => ({
                      id: field,
                      name: field,
                    }))}
                  />
                </Grid>
                <Grid>
                  <Labeled
                    style={{ marginTop: 10 }}
                    guid="body_fonts"
                    label="Body Font"
                  />
                  <SelectInput
                    style={{ marginTop: 10 }}
                    guid="body_fonts"
                    label="Body Font"
                    source={`${source}body_fonts`}
                    choices={window.bodyAllFont.map((field) => ({
                      id: field,
                      name: field,
                    }))}
                  />
                </Grid>
                <Grid>
                  <Labeled
                      style={{ marginTop: 10 }}
                      guid="stand_first"
                      label="Stand first Font"
                  />
                  <SelectInput
                      style={{ marginTop: 10 }}
                      guid="stand_first"
                      label="Stand first Font"
                      source={`${source}stand_first`}
                      choices={window.bodyAllFont.map((field) => ({
                        id: field,
                        name: field,
                      }))}
                  />
                </Grid>

              </Grid>

              <FieldArray
                myLabel="AppleNews article control tags"
                name={`${source}apple_news_article_control_tags`}
                component={FieldArrayCardFormComponent}
                arrayForm={[
                  <TextInput
                    source={`name`}
                    label="Article Control Tag"
                    helperText=""
                  />,
                ]}
              />
              <br />
              <InputLabel>
                Use "exclude-from-anf" to exclude your content from being sent
                to Apple News
              </InputLabel>
              <br />
              <InputLabel>
                If there is no control tag, so all content will be sent to Apple
                News
              </InputLabel>
              <br />
              <>
                <Labeled label={"Page Layout Json"} />
                <br />
                <InputLabel>The config of the style for Apple News</InputLabel>
                <br />
                <AceEditorInput
                  source={`${source}component_text_style`}
                  mode={"json"}
                  label={"Bottom markup"}
                  displayAceLabel={false}
                  useWorker={true}
                  placeholder="{}"
                />
              </>
              <br />
              <InputLabel>
                Send a sample article to Apple News on Future Sandbox
              </InputLabel>
              <br />
              <Button
                onClick={handleCallBack}
                variant="contained"
                color="primary"
                size="small"
                style={{ marginBottom: 15, marginTop: 10 }}
              >
                Send
              </Button>
              <br />
              <InputLabel style={{ fontWeight: 'bold' }}>
                ANF Newsletter Components
              </InputLabel>
              <Grid columns={4} style={{ marginTop: 10 }}>

                <Grid>
                  <Labeled
                      style={{ marginTop: 5 }}
                      guid="language_text"
                      label="Language Text"
                  />
                  <TextInput
                      source={`${source}apple_news_language_text`}
                      helperText="Language Text (above the button)"
                      label={"Language Text"}
                      fullWidth
                      style={{ display: "block" }}
                  />
                </Grid>
                <Grid>

                  <Labeled
                      style={{ marginTop: 5 }}
                      guid="apple_news_link_newsletter"
                      label="Link to the Newsletter"
                  />
                  <TextInput
                      source={`${source}apple_news_link_newsletter`}
                      helperText="Link to the Newsletter landing page"
                      label={"Link to the Newsletter"}
                      fullWidth
                      style={{ display: "block" }}
                  />
                </Grid>
                <Grid>
                  <Labeled
                      style={{ marginTop: 5 }}
                      guid="apple_news_background_color"
                      label="Background Color"
                  />
                  {window.secondaryLengthColors > 0 &&
                      Array.from(Array(window.secondaryLengthColors)).map(
                          (field, index) =>
                              colorPickerInPutField(
                                  index,
                                  `${source}apple_news_background_color`,
                                  "background_color"
                              )
                      )}
                </Grid>
                <Grid>
                  <Labeled
                      style={{ marginTop: 5 }}
                      guid="apple_news_button_color"
                      label="Button Color "
                  />
                  {window.secondaryLengthColors > 0 &&
                      Array.from(Array(window.secondaryLengthColors)).map(
                          (field, index) =>
                              colorPickerInPutField(
                                  index,
                                  `${source}apple_news_button_color`,
                                  "button_color"
                              )
                      )}
                </Grid>


              </Grid>
            </>
          )}
          <br />
          <FieldArray
            mylabel="ANF Section list*"
            name={`${source}anf_section`}
            component={CommonDynamicFieldsComponents}
            itemValue={"Apple ID"}
            itemKey={"Proof Category Handle"}
          />
          <InputLabel>
            *Use "all" with the main section id to send all article in the Main
            Section
          </InputLabel>
        </CardContent>
      </Collapse>
    </Grid>
  );
};

CardAppleNews.propTypes = {};

export default CardAppleNews;
